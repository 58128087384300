import React from "react";
import Global from "./styles/global";

import HomePage from "./pages/Home";
function App() {
  return (
    <>
      <Global />
      <HomePage />
    </>
  );
}

export default App;
